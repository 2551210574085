import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Spaces from "../src/components/spaces";
import Login from "../src/components/login";
import SignUp from "../src/components/signUp";
import Home from "../src/components/home";
import Products from "../src/components/products";
import Cart from "../src/components/cart";
import Payments from "../src/components/payments";
import RateOrder from "../src/components/rateOrder";
import ThanksPage from "../src/components/thanksPage";
import OrderHistory from "../src/components/orderHistory";
import AccountPage from "../src/components/account";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Spaces} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/payments" component={Payments} />
        <Route exact path="/rate-order" component={RateOrder} />
        <Route exact path="/thanks" component={ThanksPage} />
        <Route exact path="/order-history" component={OrderHistory} />
        <Route exact path="/account" component={AccountPage} />
      </Switch>
    </Router>
  );
};

export default App;
