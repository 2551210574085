import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message, Spin } from "antd";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { fnbURL } from "../../../src/constants/config";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
// import EditIcon from "../../assets/images/edit.svg";
import "antd/dist/antd.css";
import "../styles.css";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";

const Account = () => {
  const history = useHistory();
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userInfo"));
    form.setFieldsValue({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
    });
  });

  const onSubmit = (values) => {
    const number = JSON.parse(localStorage.getItem("phoneNumber"));
    const token = JSON.parse(localStorage.getItem("clientToken"));
    setLoading(true);
    const getCustomer = {
      query: `mutation {
        upsertCustomer(customer: {
            b2cCustomerId: null,
            firstname: "${values.firstName}",
            lastname: "${values.lastName}",
            mobile_no: "${number}",
            email: "${values.email}"
        }) {
            status
            message
            b2cCustomerId
        }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getCustomer,
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      const customer = response.data.data.upsertCustomer;
      if (customer.status === "200" && customer !== null) {
        localStorage.setItem("userInfo", JSON.stringify(values));
        history.push("/home");
      } else {
        message.error(customer.message);
      }
    });
  };

  const onEdit = () => {
    setEdit(true);
  };

  const handleBack = () => {
    history.push("/home");
  };

  const antIcon = <LoadingOutlined spin />;

  return (
    <Spin
      spinning={loading}
      tip="Loading..."
      indicator={antIcon}
      style={{
        position: "relative",
        top: "50vh",
        color: "#ff6339",
      }}
      size="large"
    >
      <div className="mobile-hide">
        <MenuComponent />
      </div>
      <div className="mobile-hide">
      <Scrollbars autoHide className="prodCardsScrollDesk">
        <div className="orderHistoryPageDesk">
          <Row style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Row>
                <Col span={4} style={{ alignSelf: "center" }}>
                  <img
                    src={LeftArrow}
                    alt=""
                    className="arrowLeft"
                    onClick={handleBack}
                  />
                </Col>
                <Col span={20}>
                  <p className="cartTitleDesk">Account details</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="accountMainDesk">
          <Row>
            <Col span={24} style={{ alignSelf: "center" }}>
              <p className="editText" onClick={onEdit}>
                Edit <EditOutlined />
              </p>
            </Col>
          </Row>
          <Form name="basic" onFinish={onSubmit} form={form}>
            <label className="inputLabelAccount">First Name</label>
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: "Please input your firstname!" },
              ]}
              className="formItem"
            >
              {edit === false ? (
                <Input
                  type="text"
                  size="large"
                  className="accountInputs"
                  disabled
                />
              ) : (
                <Input type="text" className="accountInputs" />
              )}
            </Form.Item>
            <label className="inputLabelAccount">Last Name</label>
            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
              className="formItem"
            >
              {edit === false ? (
                <Input
                  type="text"
                  // size="large"
                  className="accountInputs"
                  disabled
                />
              ) : (
                <Input type="text" className="accountInputs" />
              )}
            </Form.Item>
            <label className="inputLabelAccount">Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid Email",
                },
                {
                  required: true,
                  message: "Please input your Email",
                },
              ]}
              className="formItem"
            >
              {edit === false ? (
                <Input
                  type="email"
                  // size="large"
                  className="accountInputs"
                  disabled
                />
              ) : (
                <Input type="email" className="accountInputs" />
              )}
            </Form.Item>
            <div className="saveBtnDiv">
              <Form.Item>
                <Button size="large" htmlType="submit" className="saveBtn">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        </div>
        <div className="prodFooter">
        <FooterComponent />
          </div>
          </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="signUpMain">
          <div>
            <img src={LeftArrow} alt="" onClick={handleBack} />
          </div>
          <Row style={{ paddingTop: 10 }}>
            <Col span={12}>
              <h1 className="signUpText">Account</h1>
            </Col>
            <Col span={12} style={{ alignSelf: "center" }}>
              <p className="editText" onClick={onEdit}>
                Edit
              </p>
            </Col>
          </Row>
          <Form name="basic" onFinish={onSubmit} form={form}>
            <label className="inputLabelSignUp">First Name</label>
            <Form.Item
              name="firstName"
              rules={[
                { required: true, message: "Please input your firstname!" },
              ]}
              className="formItem"
            >
              {edit === false ? (
                <Input
                  type="text"
                  size="large"
                  className="signUpInputs"
                  disabled
                />
              ) : (
                <Input type="text" size="large" className="signUpInputs" />
              )}
            </Form.Item>
            <label className="inputLabelSignUp">Last Name</label>
            <Form.Item
              name="lastName"
              rules={[
                { required: true, message: "Please input your lastname!" },
              ]}
              className="formItem"
            >
              {edit === false ? (
                <Input
                  type="text"
                  size="large"
                  className="signUpInputs"
                  disabled
                />
              ) : (
                <Input type="text" size="large" className="signUpInputs" />
              )}
            </Form.Item>
            <label className="inputLabelSignUp">Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid Email",
                },
                {
                  required: true,
                  message: "Please input your Email",
                },
              ]}
              className="formItem"
            >
              {edit === false ? (
                <Input
                  type="email"
                  size="large"
                  className="signUpInputs"
                  disabled
                />
              ) : (
                <Input type="email" size="large" className="signUpInputs" />
              )}
            </Form.Item>
            <div className="nextBtnDiv">
              <Form.Item>
                <Button size="large" htmlType="submit" className="submitBtn">
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default Account;
