import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Success from "../../assets/images/success.gif";
// import MenuComponent from "../menuComponent";

const ThanksPage = () => {
  const history = useHistory();
  const [cartDetails, setCartDetails] = useState([]);
  const [restaurant, setRestaurant] = useState({});

  useEffect(() => {
    const cartList = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    const storeInfo = JSON.parse(localStorage.getItem("storeInfo"));
    setRestaurant(storeInfo);
    setCartDetails(cartList);
  }, []);

  const handleHome = () => {
    history.push("/home");
    localStorage.setItem(
      "cart",
      JSON.stringify({
        lineItems: [],
        total: 0,
        tax: 0,
        totalQty: 0,
      })
    );
    localStorage.setItem("itemsQuantity", 0);
    localStorage.removeItem("cwcOrderId");
  };

  const orderdate1 = window.localStorage.getItem("orderDate");
  const onlyDate = moment(orderdate1).format("DD-MM-YYYY");
  const onlyTime = moment(orderdate1).format("HH:mm A");

  return (
    <div>
      <div className="mobile-hide">
        <div className="thanksPageDesk">
          <div className="thanksMainDiv">
            <div style={{ marginTop: 25 }}>
              <p className="orderPlacedText">Your order has been placed</p>
              <div style={{ textAlign: "center" }}>
                <img src={Success} alt="" />
              </div>
              <p className="thankYouText">Thank you!</p>
              <p className="totalItems">
                {cartDetails.totalQty} Items - Rs.{cartDetails.total}
              </p>
              <div style={{ padding: "0px 25px" }}>
                <Row>
                  <Col span={12}>
                    <p className="paymentDetails">Payment Option</p>
                  </Col>
                  <Col span={12}>
                    <p className="paymentDetailsValues">Credit Card</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <p className="paymentDetails">Date</p>
                  </Col>
                  <Col span={12}>
                    <p className="paymentDetailsValues">
                      {onlyDate} at {onlyTime}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <p className="paymentDetails">Shop</p>
                  </Col>
                  <Col span={12}>
                    <p className="paymentDetailsValues">{restaurant.name}</p>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="nextBtnDivInCart-desk">
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  size="large"
                  htmlType="submit"
                  className="backToHomeBtn-desk"
                  onClick={handleHome}
                >
                  Back To Home
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          {/* <Row>
          <Col span={24}>
            <img src={LeftArrow} alt="" className="arrowLeft" onClick={handleHome} />
          </Col>
        </Row> */}
          <div style={{ marginTop: 50 }}>
            <p className="orderPlacedText">Your order has been placed</p>
            <div style={{ textAlign: "center" }}>
              <img src={Success} alt="" />
            </div>
            <p className="thankYouText">Thank you!</p>
            <p className="totalItems">
              {cartDetails.totalQty} Items - Rs.{cartDetails.total}
            </p>
            <div style={{ padding: "0px 25px" }}>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Payment Option</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">Credit Card</p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Date</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">
                    {onlyDate} at {onlyTime}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <p className="paymentDetails">Shop</p>
                </Col>
                <Col span={12}>
                  <p className="paymentDetailsValues">{restaurant.name}</p>
                </Col>
              </Row>
            </div>
          </div>
          <div className="nextBtnDivInCart">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                size="large"
                htmlType="submit"
                className="backToHomeBtn"
                onClick={handleHome}
              >
                Back To Home
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
