import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Skeleton } from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import { fnbURL } from "../../../src/constants/config";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import MenuComponent from "../menuComponent";
import { reverse } from "lodash";

const OrderHistory = () => {
  const history = useHistory();
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [cart, setCart] = useState({
    lineItems: [],
    total: 0,
    tax: 0,
    totalQty: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const customerId = JSON.parse(localStorage.getItem("customerId"))
      ? JSON.parse(localStorage.getItem("customerId"))
      : null;
    const getOrders = {
      query: `query {
        getMyOrders(customerId: "${customerId}") {
            cWCOrderId
            orderNo
            dateCreated
            status
            paymentMethod
            storeName
            grossAmount
            lineItems
                {
                  cWCOrderLineId
                    productCode
                    name
                    quantity
                    price
                    total
                    addOnProducts{
                        id
                        name
                        price
                    }
                }
              }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getOrders,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      if(response.status === 200) {
        const responseData = response.data.data.getMyOrders;
        responseData.map((item) => (item.isMore = false));
        let newResponseData = reverse(responseData);
        setOrderHistoryData([...newResponseData]);
        setLoading(false);
      }
    });
  }, []);

  const backToHome = () => {
    history.push("/home");
  };

  const handleMore = (value, index, fieldName) => {
    let newData = orderHistoryData;
    newData[index][fieldName] = value;
    setOrderHistoryData([...newData]);
  };

  const handleReorder = (values) => {
    values.map(item => {
      addProdToCart(item, item.quantity)
      return null
    })
    history.push("/cart");
  }

  const addProdToCart = (prod, qty) => {
    const weight = parseFloat(qty);
    const addedToCart = cart.lineItems || [];
    const index = addedToCart.findIndex((p) => p.id === prod.cWCOrderLineId);
    let newObj =
      index >= 0
        ? {
            ...addedToCart[index],
            weight: addedToCart[index].weight + weight,
          }
        : {
            id: prod.cWCOrderLineId,
            name: prod.name,
            prodCode: prod.productCode,
            price: prod.price,
            total: 0,
            tax: 0,
            taxAmount: 0,
            taxRate: 0,
            weight: weight,
          };
    const mrp = parseFloat(newObj.price) * newObj.weight;
    const tax = mrp - mrp / (1 + newObj.taxRate / 100);
    newObj.taxAmount = tax;
    newObj.total = mrp;

    if (index >= 0) {
      if (newObj.weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        addedToCart.splice(index, 1, newObj);
      }
    } else {
      addedToCart.push(newObj);
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    for (let i = 0; i < addedToCart.length; i++) {
      totalPrice += Number(addedToCart[i].total);
      totalItemsQty += addedToCart[i].weight;
      totalTax += Number(addedToCart[i].taxAmount);
      addedToCart[i].key = i;
    }
    let newCart = {
      ...cart,
      lineItems: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      totalQty: totalItemsQty,
    };

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    localStorage.setItem("itemsQuantity", JSON.stringify(totalItemsQty));
  };

  const feedBack = () => {
    history.push("/rate-order");
  };

  return (
    <div>
      <div className="cartMain">
      <Skeleton loading={loading} style={{padding: "0px 15px"}} paragraph={{ rows: 4 }} />
        <Skeleton loading={loading} style={{padding: "0px 15px"}} paragraph={{ rows: 4 }} />
        <Skeleton loading={loading} style={{padding: "0px 15px"}} paragraph={{ rows: 4 }}>
        <Row>
          <Col span={8}>
            <img
              src={LeftArrow}
              alt=""
              className="arrowLeft"
              onClick={backToHome}
            />
          </Col>
          <Col span={12}>
            <p className="cartTitle">Order History</p>
          </Col>
        </Row>
        <div style={{ marginTop: 10 }}>
          <Scrollbars autoHide className="orderHistoryScroll">
            {orderHistoryData.map((data, dataIndex) => (
              <div style={{ padding: 10 }}>
                <Card bodyStyle={{ padding: 15 }} className="orderDetailsCard">
                  <Row>
                    <Col span={24}>
                      <p className="OrderDate">{data.dateCreated}</p>
                    </Col>
                  </Row>
                  <div>
                    {data.isMore ? (
                      <>
                        <Scrollbars autoHide className="orderDetailsScroll">
                          {data?.lineItems?.map((item, index) => (
                            <div>
                              <Row style={{ marginBottom: 5 }}>
                                <Col span={17}>
                                  <p className="OrderProdName">{item.name}</p>
                                </Col>
                                <Col span={3}>
                                  <p className="OrderProdQty">
                                    {item.quantity}
                                  </p>
                                </Col>
                                <Col span={4}>
                                  <p className="OrderProdPrice">
                                    &#8377; {item.price}
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Scrollbars>
                        <p className="more">
                          <span>
                            less{" "}
                            <CaretUpOutlined
                              style={{ verticalAlign: "middle" }}
                              onClick={() => {
                                handleMore(!data.isMore, dataIndex, "isMore");
                              }}
                            />
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col span={15}>
                            <p className="OrderProdName">
                              {data.lineItems.length > 0
                                ? data.lineItems[0].name
                                : ""}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p className="OrderProdQty">
                              {data.lineItems.length > 0
                                ? "X" + data.lineItems[0].quantity
                                : ""}
                            </p>
                          </Col>
                          <Col span={6}>
                            <p className="OrderProdPrice">
                              &#8377; {data.grossAmount.toFixed(2)}
                            </p>
                          </Col>
                        </Row>
                        <p className="more" style={{display: data?.lineItems?.length > 1 ? "block" : "none"}}>
                          <span>
                            More{" "}
                            <CaretDownOutlined
                              style={{ verticalAlign: "middle" }}
                              onClick={() => {
                                handleMore(!data.isMore, dataIndex, "isMore");
                              }}
                            />
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                  <Row gutter={[8, 0]}>
                    <Col span={12}>
                      <Button className="reOrderBtn" size="large" onClick={() => handleReorder(data.lineItems)}> 
                        REORDER
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        className="rateOrderBtn"
                        size="large"
                        onClick={feedBack}
                      >
                        RATE ORDER
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </div>
            ))}
          </Scrollbars>
        </div>
        </Skeleton>
      </div>
      <MenuComponent />
    </div>
  );
};

export default OrderHistory;
