import React, { useState } from "react";
import { Row, Col, Drawer, Badge } from "antd";
import Animate from "rc-animate";
import { useHistory, useLocation } from "react-router-dom";
import HomeIcon from "../../assets/images/Icon_home.svg";
import BasketIcon from "../../assets/images/basketIcon.svg";
import MenuIcon from "../../assets/images/menuIcon.svg";
import ArrowLeft from "../../assets/images/arrow-left.svg";
import UserIconGrey from "../../assets/images/account-circle-grey.svg";
import HomeLine from "../../assets/images/home-black.svg";
import HomeGreen from "../../assets/images/home-line.svg";
import TasksLine from "../../assets/images/tasks-line.svg";
import TasksGreen from "../../assets/images/tasks-green.svg";
import WalletLine from "../../assets/images/wallet-line.svg";
import WalletGreen from "../../assets/images/wallet-green.svg";
import BellLine from "../../assets/images/bell-line.svg";
import BellGreen from "../../assets/images/notifications-green.svg";
import UserLine from "../../assets/images/user-line.svg";
import UserGreen from "../../assets/images/user-line-green.svg";
import LogoutLine from "../../assets/images/logout-line.svg";
import LogoutGreen from "../../assets/images/logout-green.svg";
import HomeGrey from "../../assets/images/home-grey.svg";
import BasketBlue from "../../assets/images/basket-blue.svg";
import CWSuitLogo from "../../assets/images/cw-suit-green.png";
import UserIcon from "../../assets/images/account-circle.svg";
import ArrowLeftWhite from "../../assets/images/arrow-white.svg";
import MenuBlack from "../../assets/images/menu-big.svg";
import CartIcon from "../../assets/images/basket-big.svg";

const MenuComponent = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userName = userInfo.firstName + " " + userInfo.lastName;

  const history = useHistory();
  const locaation = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const showSideMenu = () => {
    setShowMenu(true);
  };

  const sideMenuClose = () => {
    setShowMenu(false);
  };

  const handleHome = () => {
    history.push("/home");
  };

  const handleOrderHistory = () => {
    history.push("/order-history");
  };

  const handleAccount = () => {
    history.push("/account");
  };

  const handleLogout = () => {
    localStorage.removeItem("clientToken");
    history.push("/");
  };

  const handleCart = () => {
    history.push("/cart");
  };

  const [selectedMenu, setSelectedMenu] = useState("Home");

  const menuItemsList = [
    {
      title: "Home",
      image: HomeLine,
      selectedImage: HomeGreen
    },
    {
      title: "Order History",
      image: TasksLine,
      selectedImage: TasksGreen
    },
    {
      title: "Wallet",
      image: WalletLine,
      selectedImage: WalletGreen
    },
    {
      title: "Notifications",
      image: BellLine,
      selectedImage: BellGreen
    },
    {
      title: "Account",
      image: UserLine,
      selectedImage: UserGreen
    },
    {
      title: "Logout",
      image: LogoutLine,
      selectedImage: LogoutGreen
    },
  ];

  const handleSelectedMenu = (value) => {
    setSelectedMenu(value);
  }

  const [sideMenuDrawervisible, setSideMenuDrawervisible] = useState(false);
  const openSideMenu = () => {
    setSideMenuDrawervisible(true);
  };
  const onClose = () => {
    setSideMenuDrawervisible(false);
  };

  return (
    <div>
      <div className="mobile-hide">
        <Row className="headerRow">
          <Col span={2} style={{ alignSelf: "center" }}>
            <img src={MenuBlack} alt="" onClick={openSideMenu} />
          </Col>
          <Col span={3} style={{ alignSelf: "center" }}>
            <img src={CWSuitLogo} alt="" width="90%" onClick={handleHome} />
          </Col>
          <Col span={16} />
          <Col span={2} style={{ alignSelf: "center", textAlign: "right" }}>
            <Badge count={JSON.parse(localStorage.getItem("itemsQuantity"))}>
              <img src={CartIcon} alt="" onClick={handleCart} />
            </Badge>
          </Col>
          <Col span={1} style={{ alignSelf: "center" }}>
            <img
              src={UserIcon}
              alt=""
              onClick={handleAccount}
              width="50%"
              style={{ marginTop: 5, float: "right" }}
            />
          </Col>
        </Row>
        <Drawer
          title={
            <div>
              <Row>
                <Col span={4}>
                  <img src={ArrowLeftWhite} alt="Status" onClick={onClose} />
                </Col>
                <Col span={20}>
                  <p className="spacesTextInMenuDesk">SPACES</p>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <img
                    src={UserIconGrey}
                    alt="Status"
                    className="userImageIcon"
                    // onClick={this.onCartArrow}
                  />
                </Col>
                <Col span={20}>
                  <p className="userNameInMenuDesk">{userName}</p>
                </Col>
              </Row>
            </div>
          }
          placement="left"
          closable={false}
          visible={sideMenuDrawervisible}
        >
          {menuItemsList.map((item) => (
            <Row
              onClick={
                item.title === "Home"
                  ? handleHome
                  : item.title === "Order History"
                  ? handleOrderHistory
                  : item.title === "Account"
                  ? handleAccount
                  : item.title === "Logout"
                  ? handleLogout
                  : ""
              }
              className={item.title === "Logout" ? "logoutMenu" : ""}
            >
              <Col span={1}>
                <div
                  className="selectedMenuItem-desk"
                  style={{
                    visibility:
                      selectedMenu === item.title ? "visible" : "hidden",
                  }}
                ></div>
              </Col>
              <Col
                span={23}
                style={{ paddingLeft: 7, paddingTop: 7, cursor: "pointer" }}
                onClick={() => handleSelectedMenu(item.title)}
              >
                <img
                  src={
                    selectedMenu === item.title
                      ? item.selectedImage
                      : item.image
                  }
                  alt=""
                  // className="menuIcons"
                />
                <span
                  className={
                    selectedMenu === item.title ? "menuTextActive" : "menuText"
                  }
                >
                  {item.title}
                </span>
              </Col>
            </Row>
          ))}
        </Drawer>
      </div>
      <div className="desktop-hide">
      <Row className="menuRow">
        <Col span={8}>
          <Animate transitionName="fade" transitionAppear>
            <div key="1">
              <img
                src={locaation.pathname === "/cart" ? HomeGrey : HomeIcon}
                alt=""
                onClick={handleHome}
              />
            </div>
          </Animate>
        </Col>
        <Col span={8} style={{ textAlign: "center" }} onClick={handleCart}>
          <Animate transitionName="fade" transitionAppear>
            <div key="1">
              <Badge count={JSON.parse(localStorage.getItem("itemsQuantity"))}>
                <img
                  src={locaation.pathname === "/cart" ? BasketBlue : BasketIcon}
                  alt=""
                />
              </Badge>
            </div>
          </Animate>
        </Col>
        <Col span={8} style={{ textAlign: "right" }}>
          <Animate transitionName="fade" transitionAppear>
            <div key="1">
              <img src={MenuIcon} alt="" onClick={showSideMenu} />
            </div>
          </Animate>
        </Col>
      </Row>
      <Drawer
        title={null}
        width="100%"
        placement="left"
        closable={false}
        visible={showMenu}
        bodyStyle={{ padding: 0 }}
      >
        <div className="drawerMainDiv">
          <Row>
            <Col span={4}>
              <img
                src={ArrowLeft}
                alt="Status"
                style={{
                  paddingTop: "12px",
                }}
                onClick={sideMenuClose}
              />
            </Col>
            <Col span={20}>
              <img
                src={CWSuitLogo}
                alt=""
                width="35%"
                style={{ marginTop: 7, marginLeft: "-4px" }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col span={4}>
              <img
                src={UserIconGrey}
                alt="Status"
                className="userImageIcon"
              />
            </Col>
            <Col span={20}>
              <p className="userNameInMenu">{userName}</p>
            </Col>
          </Row>
        </div>
        {menuItemsList.map((item) => (
          <Row onClick={
            item.title === "Home" ? handleHome : 
            item.title === "Order History" ? handleOrderHistory :
            item.title === "Account" ? handleAccount :
            item.title === "Logout" ? handleLogout : ""
          } style={{
            marginTop: item.title === "Logout" ? "15rem" : ""
          }}>
            <Col span={1}>
              <div
                className="selectedMenuItem"
                style={{
                  visibility:
                    selectedMenu === item.title
                      ? "visible"
                      : "hidden",
                }}
              ></div>
            </Col>
            <Col span={23} style={{paddingLeft: 7, paddingTop: 7}} onClick={() => handleSelectedMenu(item.title)}>
              <img src={selectedMenu === item.title ? item.selectedImage : item.image} alt="" className="menuIcons"/>
              <span className={selectedMenu === item.title ? "menuTextActive" : "menuText"}>{item.title}</span>
            </Col>
          </Row>
        ))}
      </Drawer>
      </div>
    </div>
  );
};

export default MenuComponent;
