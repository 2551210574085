import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, message, Spin } from "antd";
import {
  MinusOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import QueueAnim from "rc-queue-anim";
import Animate from "rc-animate";
import { fnbURL } from "../../../src/constants/config";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import LeftArrow from "../../assets/images/leftArrow.svg";
import TrashBin from "../../assets/images/trash-bin.svg";
import Closegrey from "../../assets/images/close-grey.svg";
import DefaultImage from "../../assets/images/default-product.png";
import MenuComponent from "../menuComponent";
import FooterComponent from "../footer";
import _ from "lodash";

const Cart = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [cart, setCart] = useState({
    lineItems: [],
    total: 0,
    tax: 0,
    totalQty: 0,
  });
  // const [storeInfo, setStoreInfo] = useState({});

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    // const storeDetails = JSON.parse(localStorage.getItem("storeInfo"))
    //   ? JSON.parse(localStorage.getItem("storeInfo"))
    //   : {};
    // setStoreInfo(storeDetails);
    setCart(cartItems);
  }, []);

  const setDefaultImage = (e) => {
    e.target.src = DefaultImage;
  };

  const addProdToCart = (prod, qty) => {
    const weight = parseFloat(qty);
    const addedToCart = cart.lineItems || [];
    const index = addedToCart.findIndex((p) => p.id === prod.id);
    let newObj =
      index >= 0
        ? {
            ...addedToCart[index],
            weight: addedToCart[index].weight + weight,
          }
        : {
            id: prod.id,
            name: prod.name,
            prodCode: prod.sku,
            price: prod.price,
            total: 0,
            tax: 0,
            taxAmount: 0,
            taxRate: 0,
            weight: weight,
          };
    const mrp = parseFloat(newObj.price) * newObj.weight;
    const tax = mrp - mrp / (1 + newObj.taxRate / 100);
    newObj.taxAmount = tax;
    newObj.total = mrp;

    if (index >= 0) {
      if (newObj.weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        addedToCart.splice(index, 1, newObj);
      }
    } else {
      addedToCart.push(newObj);
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    for (let i = 0; i < addedToCart.length; i++) {
      totalPrice += Number(addedToCart[i].total);
      totalItemsQty += addedToCart[i].weight;
      totalTax += Number(addedToCart[i].taxAmount);
      addedToCart[i].key = i;
    }
    let newCart = {
      ...cart,
      lineItems: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      totalQty: totalItemsQty,
    };

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    localStorage.setItem("itemsQuantity", JSON.stringify(totalItemsQty));
  };

  const handleNext = () => {
    if (cart.lineItems.length > 0) {
      const subtotalAmount = cart.total;
      const mobileNumber = localStorage.getItem("phoneNumber");
      const totalAmount = subtotalAmount;
      const val = Math.floor(1000 + Math.random() * 9000);
      const date = new Date();
      const formatteddate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
      let name = "";
      let productCode = "";
      let quantity = "";
      let price = "";
      let subTotal = "";
      //  let subtotalTax = ''
      let total = "";
      //  let totalTax = ''
      const arrayForMutation = [];
      const itemLength = cart.lineItems.length;
      localStorage.setItem("totalProducts", itemLength);
      for (let index = 0; index < cart.lineItems.length; index++) {
        // const element = cart.lineItems[index];
        name = cart.lineItems[index].name;
        productCode = cart.lineItems[index].prodCode;
        quantity = cart.lineItems[index].weight;
        price = cart.lineItems[index].price;
        subTotal = cart.lineItems[index].price;
        total = cart.lineItems[index].price;
        arrayForMutation.push(
          `{
          name: "${name}"
          productCode: "${productCode}"
          quantity: ${quantity}
          price: ${price}
          subTotal: ${subTotal}
          subtotalTax: 0.00
          total: ${total}
          totalTax: 0.00
          addOnProducts: []
        }`
        );
      }
      const getBookinOrder = {
        query: `mutation {
        createOrder(
            order: {
                orderNo: "${val}"
                createdVia: "rest-api"
                storeId: "8A51A3369DCD43F19EC9DD29AC12D33A"
                status: "NEW"
                currency: "INR"
                dateCreated: "${formatteddate}"
                discountTotal: 0.0
                discountTax: 0.0
                cartTax: 0.0
                total: ${totalAmount}
                totalTax: 0
                pricesIncludeTax: "false"
                mobileNo: ${mobileNumber}
                customerIpAddress: ""
                customerNote: ""
                paymentMethod: "razorpay"
                paymentMethodTitle: "Credit Card/Debit Card/NetBanking"
                transactionId: ""
                cartHash: ""
                metaData: [{ key: "_dokan_vendor_id", value: "1" }]
                lineItems: [${arrayForMutation}]
            }
        ) {
            status
            message
            cwcOrderId
            razorpayOrderId
        }
    }`,
      };
      Axios({
        url: fnbURL,
        method: "POST",
        data: getBookinOrder,
        headers: {
          clientId: "39BC576048054849BFBFEDBF29142A3E",
        },
      }).then((response) => {
        const respose = response.data.data.createOrder;
        if (respose.message === "Order created successfully.") {
          const razorPayOrderId =
            response.data.data.createOrder.razorpayOrderId;
          const cwcOrderId = response.data.data.createOrder.cwcOrderId;
          // do payment for successful orders
          const options = {
            key: "rzp_test_3sW37wBTmaMDnK",
            amount: `${totalAmount * 100}`,
            currency: "INR",
            name: "CW Food Ordering",
            description: "Test Transaction",
            image: "https://example.com/your_logo",
            order_id: `${razorPayOrderId}`,
            prefill: {
              name: "Gaurav Kumar",
              email: "gaurav.kumar@example.com",
              contact: "9999999999",
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            handler: (response) => verifyPayment(cwcOrderId, response),
            theme: {
              color: "#3399cc",
            },
          };
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else {
          message.error("Failed to Place Order");
        }
      });
    } else {
      message.error("No Products in cart!");
    }
  };

  const verifyPayment = (cwcOrderId, response) => {
    setLoading(true);
    const verifyPayment = {
      query: `mutation {
        verifyOrderPayment(
            razorPayOrder: {
                cwcOrderId: "${cwcOrderId}"
                razorpayOrderId: "${response.razorpay_order_id}"
                razorpayPaymentId: "${response.razorpay_payment_id}"
                razorpaySignature: "${response.razorpay_signature}"
            }
        ) {
            status
            message
            cwcOrderId
            razorpayOrderId
            razorpayPaymentId
            razorpaySignature
        }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      data: verifyPayment,
      headers: {
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      if (
        response.data.data.verifyOrderPayment.message ===
        "Payment validated successfully."
      ) {
        const date = new Date();
        const formatteddate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
        localStorage.setItem("cwcOrderId", cwcOrderId);
        localStorage.setItem("orderDate", formatteddate);
        history.push("/thanks");
        setLoading(false);
        setCart({});
      }
    });
  };

  const handleBack = () => {
    history.push("/products");
  };

  const deleteCart = () => {
    setCart({
      lineItems: [],
      total: 0,
      tax: 0,
      totalQty: 0,
    });
    localStorage.setItem(
      "cart",
      JSON.stringify({
        lineItems: [],
        total: 0,
        tax: 0,
        totalQty: 0,
      })
    );
    localStorage.setItem("itemsQuantity", JSON.stringify(0));
  };

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
        <Scrollbars autoHide className="prodCardsScrollDesk">
        <div className="cartDesk">
          {/* <Scrollbars autoHide className="cartSroll"> */}
          <Row style={{ marginBottom: 10 }}>
              <Col span={12}>
                <Row>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <img
                      src={LeftArrow}
                      alt=""
                      className="arrowLeft"
                      onClick={handleBack}
                    />
                  </Col>
                  <Col span={20}>
                    <p className="cartTitleDesk">Cart</p>
                  </Col>
                </Row>
              </Col>
              <Col
                span={12}
                style={{ textAlign: "right", alignSelf: "center" }}
              >
                <p
                  className="clearAll"
                  onClick={() =>
                    cart.lineItems.length > 0 ? deleteCart() : ""
                  }
                >
                  <img
                    src={TrashBin}
                    alt=""
                    // onClick={handleBack}
                    width="3%"
                  />{" "}
                  &nbsp; Clear All &nbsp;
                  <img
                    src={Closegrey}
                    alt=""
                    // onClick={handleBack}
                    width="2.5%"
                  />
                </p>
              </Col>
            </Row>
          <div className="cartItemsDiv">
            {/* <Scrollbars autoHide className="itemsInCartScroll"> */}
              <div>
                {Object.entries(
                  _.groupBy([...cart?.lineItems] || [], "categoryName")
                ).map(([key, value]) => (
                  <>
                    {key && key !== "undefined" && (
                      <p className="selectCategoryNameInCart">{key}</p>
                    )}
                    {value?.map((item, index) => (
                      <Card
                        bodyStyle={{ padding: 10 }}
                        style={{ marginBottom: 10, borderRadius: "6px" }}
                      >
                        <Row>
                          <Col span={8}>
                            <Animate transitionName="fade" transitionAppear>
                              <div key="1">
                                <img
                                  src={item.imgUrl || ""}
                                  onError={setDefaultImage}
                                  alt=""
                                  className="prodImg"
                                />
                              </div>
                            </Animate>
                          </Col>
                          <Col span={16}>
                            <QueueAnim name="QueueAnim" delay={300}>
                              <div key="a">
                                <Row>
                                  <Col span={24}>
                                    <p className="prodName">{item.name}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={15}>
                                    <p className="prodPrice">
                                      &#8377; {item.price} &nbsp;{" "}
                                      <span className="regPrice">
                                        &#8377; {item.price}
                                      </span>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={12}
                                    style={{ alignSelf: "center" }}
                                  >
                                    <p className="prodWeight">500g</p>
                                  </Col>
                                  <Col span={12} className="prodQty">
                                    <p className="IncDecBtns">
                                      <span className="minusBtn">
                                        <MinusOutlined
                                          onClick={() =>
                                            addProdToCart(item, -1)
                                          }
                                        />{" "}
                                      </span>
                                      &ensp;
                                      {item.weight}
                                      &ensp;
                                      <span className="plusBtn">
                                        <PlusOutlined
                                          onClick={() => addProdToCart(item, 1)}
                                        />
                                      </span>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </QueueAnim>
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </>
                ))}
              </div>
            {/* </Scrollbars> */}
            <div style={{ marginTop: 10 }}>
            <p className="billDetailsText">Bill Details</p>
          </div>
          <div>
            <Row style={{ padding: "0px 10px" }}>
              <Col span={12}>
                <p className="subTotal">Subtotal</p>
              </Col>
              <Col span={12}>
                <p className="subTotalValue">
                  &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                </p>
              </Col>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
              <Col span={12}>
                <p className="subTotal">Tax & Fees</p>
              </Col>
              <Col span={12}>
                <p className="subTotalValue">
                  &#8377; {_.sumBy(cart.lineItems, "tax") || 0}
                </p>
              </Col>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
              <Col span={12}>
                <p className="totalLabel">Total</p>
              </Col>
              <Col span={12}>
                <p className="totalValue">
                  &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                </p>
              </Col>
            </Row>
          </div>
          <div className="nextBtnDivInCart">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                size="large"
                htmlType="submit"
                className="nextBtnInCart"
                onClick={handleNext}
              >
                Next
              </Button>
            </Form.Item>
          </div>
          </div>
          {/* </Scrollbars> */}
        </div>
        <div className="prodFooter">
          <FooterComponent />
          </div>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="cartMain">
          {/* <Scrollbars autoHide className="cartSroll"> */}
          <Row>
            <Col span={10}>
              <img
                src={LeftArrow}
                alt=""
                className="arrowLeft"
                onClick={handleBack}
              />
            </Col>
            <Col span={14}>
              <p className="cartTitle">Cart</p>
            </Col>
          </Row>
          <div style={{ marginTop: 10 }}>
            <Scrollbars autoHide className="itemsInCartScroll">
              <div>
                {Object.entries(
                  _.groupBy([...cart?.lineItems] || [], "categoryName")
                ).map(([key, value]) => (
                  <>
                    {key && key !== "undefined" && (
                      <p className="selectCategoryNameInCart">{key}</p>
                    )}
                    {value?.map((item, index) => (
                      <Card
                        bodyStyle={{ padding: 10 }}
                        style={{ marginBottom: 10, borderRadius: "6px" }}
                      >
                        <Row>
                          <Col span={8}>
                            <Animate transitionName="fade" transitionAppear>
                              <div key="1">
                                <img
                                  src={item.imgUrl || ""}
                                  onError={setDefaultImage}
                                  alt=""
                                  className="prodImg"
                                />
                              </div>
                            </Animate>
                          </Col>
                          <Col span={16}>
                            <QueueAnim name="QueueAnim" delay={300}>
                              <div key="a">
                                <Row>
                                  <Col span={24}>
                                    <p className="prodName">{item.name}</p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={15}>
                                    <p className="prodPrice">
                                      &#8377; {item.price} &nbsp;{" "}
                                      <span className="regPrice">
                                        &#8377; {item.price}
                                      </span>
                                    </p>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    span={12}
                                    style={{ alignSelf: "center" }}
                                  >
                                    <p className="prodWeight">500g</p>
                                  </Col>
                                  <Col span={12} className="prodQty">
                                    <p className="IncDecBtns">
                                      <span className="minusBtn">
                                        <MinusOutlined
                                          onClick={() =>
                                            addProdToCart(item, -1)
                                          }
                                        />{" "}
                                      </span>
                                      &ensp;
                                      {item.weight}
                                      &ensp;
                                      <span className="plusBtn">
                                        <PlusOutlined
                                          onClick={() => addProdToCart(item, 1)}
                                        />
                                      </span>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </QueueAnim>
                          </Col>
                        </Row>
                      </Card>
                    ))}
                  </>
                ))}
              </div>
            </Scrollbars>
          </div>
          <div style={{ marginTop: 10 }}>
            <p className="billDetailsText">Bill Details</p>
          </div>
          <div>
            <Row style={{ padding: "0px 10px" }}>
              <Col span={12}>
                <p className="subTotal">Subtotal</p>
              </Col>
              <Col span={12}>
                <p className="subTotalValue">
                  &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                </p>
              </Col>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
              <Col span={12}>
                <p className="subTotal">Tax & Fees</p>
              </Col>
              <Col span={12}>
                <p className="subTotalValue">
                  &#8377; {_.sumBy(cart.lineItems, "tax") || 0}
                </p>
              </Col>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
              <Col span={12}>
                <p className="totalLabel">Total</p>
              </Col>
              <Col span={12}>
                <p className="totalValue">
                  &#8377; {_.sumBy(cart.lineItems, "total").toFixed(2)}
                </p>
              </Col>
            </Row>
          </div>
          <div className="nextBtnDivInCart">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                size="large"
                htmlType="submit"
                className="nextBtnInCart"
                onClick={handleNext}
              >
                Next
              </Button>
            </Form.Item>
          </div>
          {/* </Scrollbars> */}
        </div>
        <MenuComponent />
      </div>
    </div>
  );
};

export default Cart;
