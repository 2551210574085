import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spin, Skeleton, Input } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import Texty from "rc-texty";
import "rc-texty/assets/index.css";
import QueueAnim from "rc-queue-anim";
import Animate from "rc-animate";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { prodCategoryUrl } from "../../../src/constants/config";
import { Scrollbars } from "react-custom-scrollbars";
import DefaultImage from "../../assets/images/default-product.png";
import UserIcon from "../../assets/images/user-green.svg";
import BannerOne from "../../assets/images/bannerOne.png";
import BannerTwo from "../../assets/images/bannerTwo.png";
import BannerThree from "../../assets/images/bannerThree.png";
import BannerFour from "../../assets/images/bannerFour.png";
import BannerFive from "../../assets/images/banner1.png";
import BannerSix from "../../assets/images/banner2.png";
import BannerSeven from "../../assets/images/banner3.png";
import BannerEight from "../../assets/images/banner4.png";
import AdvBg from "../../assets/images/adv-bg.png";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";
import MenuComponent from "../menuComponent";
import _ from "lodash";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FooterComponent from "../footer";

const Home = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [prodCategoryList, setProdCategoryList] = useState([]);

  useEffect(() => {
    setLoading(true);
    Axios.get(prodCategoryUrl).then((response) => {
      const { data } = response;
      let filteredData = _.filter(data, (item) => item.parent === 0);
      setProdCategoryList(filteredData);
      localStorage.setItem("categories", JSON.stringify(data));
      setLoading(false);
    });
  }, []);

  const setDefaultImage = (e) => {
    e.target.src = DefaultImage;
  };

  const handleProd = (key, value) => {
    setLoading(true);
    prodCategoryList.map((data) => {
      if (key === data.id) {
        let categoryId = data.id;
        getProducts(categoryId);
      }
      return true;
    });
  };

  const getProducts = (categoryId) => {
    Axios.get(
      `https://commerce-test.mycw.in//wp-json/wc/v2/products?status=publish&lang=en&per_page=10&page=1&category=${categoryId}&consumer_key=ck_d837abcdccd83195ad0e2417cbab8fc8e414f9e5&consumer_secret=cs_574c8be38cec9519c6da0b3b7b03d16180c5c77a`
    ).then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        let filterCategoryList = [];
        _.map(responseData, (item) => {
          item.categoryId = item?.categories?.[0]?.id || "";
          item.categoryName = item?.categories?.[0]?.name || "";
          filterCategoryList.push(...item.categories);
        });
        let newCategoryData = _.uniqBy(filterCategoryList, "id");
        let selectedSubCategory = newCategoryData[0];
        let selectedSubCategoryProducts = _.filter(
          responseData,
          (item) => item.categoryId === selectedSubCategory.id
        );
        localStorage.setItem(
          "productsForCategory",
          JSON.stringify(responseData)
        );
        localStorage.setItem("subCategories", JSON.stringify(newCategoryData));
        localStorage.setItem(
          "selectedSubCategory",
          JSON.stringify(selectedSubCategory)
        );
        localStorage.setItem(
          "selectedSubCategoryProducts",
          JSON.stringify(selectedSubCategoryProducts)
        );
        history.push("/products");
      }
    });
  };

  const handleAccount = () => {
    history.push("/account");
  };

  const antIcon = <LoadingOutlined spin />;

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
        <Scrollbars autoHide className="restCardsScroll-desk">
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "15px 15px 0px" }}
            paragraph={{ rows: 4 }}
          />
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "15px 15px 0px" }}
            paragraph={{ rows: 4 }}
          />
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "15px 15px 0px" }}
            paragraph={{ rows: 4 }}
          >
            <div className="desktop-first-bg">
              <p className="heading">Lorem ipsum dolor sit amet</p>
              <p className="heading-para">
                Lorem ipsum dolor sit amet, consetetur
              </p>
              <Input
                placeholder="What are you looking for..."
                className="heading-input"
                // suffix={<SearchOutlined style={{color: "#BBBBBB"}} />}
              />
            </div>
            <div className="mainDiv-desktop">
              <Row>
                <Col span={6}>
                  <img src={BannerFive} alt="" className="bannerImg" />
                </Col>
                <Col span={6}>
                  <img src={BannerSix} alt="" className="bannerImg" />
                </Col>
                <Col span={6}>
                  <img src={BannerSeven} alt="" className="bannerImg" />
                </Col>
                <Col span={6}>
                  <img src={BannerEight} alt="" className="bannerImg" />
                </Col>
              </Row>
              <div style={{ marginBottom: 35 }} key="h">
                <p className="shop-by-category">Shop By Catergory</p>
                <Row gutter={[8, 8]}>
                  {prodCategoryList &&
                    prodCategoryList?.map((item, index) => (
                      <Col span={4} style={{ textAlign: "center" }}>
                        <Animate transitionName="fade" transitionAppear>
                          {/* <div
                                  className="prodCategoryImgDiv-desk"
                                > */}
                          <img
                            src={item?.image?.src || ""}
                            alt=""
                            onError={setDefaultImage}
                            className="categoryImg-desk"
                            onClick={() => handleProd(item.id, item.name)}
                          />
                          {/* </div> */}
                        </Animate>
                        <p className="categoryName-desk">
                          <Texty type="left" mode="sync">
                            {item.name.replace("&amp;", "&")}
                          </Texty>
                        </p>
                      </Col>
                    ))}
                </Row>
                {prodCategoryList.length > 0 && (
                  <p className="seeMore">
                    <Texty type="left" mode="sync">
                      See More
                    </Texty>
                  </p>
                )}
              </div>
            </div>
            <div style={{ marginBottom: 70, marginTop: 40 }}>
              <img src={AdvBg} width="100%" height="100%" alt="" />
            </div>
            <div style={{ marginBottom: 30 }}>
              <FooterComponent />
            </div>
          </Skeleton>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <div className="mainDiv">
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "15px 15px 0px" }}
            paragraph={{ rows: 4 }}
          />
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "15px 15px 0px" }}
            paragraph={{ rows: 4 }}
          />
          <Skeleton
            avatar
            loading={loading}
            style={{ padding: "15px 15px 0px" }}
            paragraph={{ rows: 4 }}
          >
            <Scrollbars autoHide className="restCardsScroll">
              <Row key="a">
                <Col span={20}>
                  <p className="greetingText">
                    Hello,{" "}
                    {userInfo.firstName !== null ? userInfo.firstName : "CW"}
                  </p>
                </Col>
                <Col span={4}>
                  <Animate transitionName="fade" transitionAppear>
                    <div key="1">
                      <img
                        src={UserIcon}
                        alt=""
                        className="userImage"
                        onClick={handleAccount}
                      />
                    </div>
                  </Animate>
                </Col>
              </Row>
              <Animate transitionName="fade" transitionAppear>
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                >
                  <img src={BannerOne} alt="" className="bannerImg" />
                  <img src={BannerTwo} alt="" className="bannerImg" />
                  <img src={BannerThree} alt="" className="bannerImg" />
                  <img src={BannerFour} alt="" className="bannerImg" />
                </Carousel>
              </Animate>
              <Animate transitionName="fade" transitionAppear>
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                >
                  <img src={BannerFive} alt="" className="bannerImg" />
                  <img src={BannerSix} alt="" className="bannerImg" />
                  <img src={BannerSeven} alt="" className="bannerImg" />
                  <img src={BannerEight} alt="" className="bannerImg" />
                </Carousel>
              </Animate>
              <div>
                <div className="restaurantCardsList">
                  <QueueAnim
                    className="demo-content"
                    delay={300}
                    animConfig={[
                      { opacity: [1, 0], translateY: [0, 50] },
                      { opacity: [1, 0], translateY: [0, -50] },
                    ]}
                  >
                    <div style={{ marginBottom: 35 }} key="h">
                      <p className="restaurantsTitle">Shop By Category</p>
                      <Row gutter={[8, 8]}>
                        {prodCategoryList &&
                          prodCategoryList?.map((item, index) => (
                            <Col span={6}>
                              <Animate transitionName="fade" transitionAppear>
                                <Card
                                  bodyStyle={{ padding: 10 }}
                                  className="prodCategoryImgDiv"
                                >
                                  <img
                                    src={item?.image?.src || ""}
                                    alt=""
                                    onError={setDefaultImage}
                                    className="categoryImg"
                                    onClick={() =>
                                      handleProd(item.id, item.name)
                                    }
                                  />
                                </Card>
                              </Animate>
                              <p className="categoryName">
                                <Texty type="left" mode="sync">
                                  {item.name.replace("&amp;", "&")}
                                </Texty>
                              </p>
                            </Col>
                          ))}
                      </Row>
                      {prodCategoryList.length > 0 && (
                        <p className="seeMore">
                          <Texty type="left" mode="sync">
                            See More
                          </Texty>
                        </p>
                      )}
                    </div>
                  </QueueAnim>
                </div>
              </div>
            </Scrollbars>
          </Skeleton>
        </div>
        <MenuComponent />
      </div>
    </div>
  );
};

export default Home;
