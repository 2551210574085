import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Radio,
  Modal,
  Input,
  Switch,
} from "antd";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import LeftArrow from "../../assets/images/leftArrow.svg";
import CreditCard from "../../assets/images/creditCard.png";
import Paypal from "../../assets/images/paypal.png";
import GooglePay from "../../assets/images/googlePay.png";
import Phonepe from "../../assets/images/phonePe.png";
import Visa from "../../assets/images/visa.png";
import CloseIcon from "../../assets/images/closeIcon.svg";
import MenuComponent from "../menuComponent";

const Payment = () => {
  const history = useHistory();

  const [cartTotal, setCartTotal] = useState([]);

  useEffect(() => {
    const cartList = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    setCartTotal(cartList);
  }, []);

  const [addNewPaymentCardModal, setAddNewPaymentCardModal] = useState(false);

  const handleAddNewPaymentCardModal = () => {
    setAddNewPaymentCardModal(true);
  };

  const handleAddNewPaymentCardModalClose = () => {
    setAddNewPaymentCardModal(false);
  };

  const handleRemoveCard = (checked) => {
    console.log(`switch to ${checked}`);
  };

  const handlePaymentNext = () => {
    history.push("/thanks");
  };

  return (
    <div>
      <div className="cartMain">
        {/* <Scrollbars autoHide className="cartSroll"> */}
        <Row>
          <Col span={10}>
            <img src={LeftArrow} alt="" className="arrowLeft" />
          </Col>
          <Col span={14}>
            <p className="cartTitle">Payment</p>
          </Col>
        </Row>
        <div style={{ marginTop: 20 }}>
          <Card bodyStyle={{ padding: 20 }} className="restDetailsCardInCart">
            <Row>
              <Col span={12}>
                <p className="totalAmount">Total Amount</p>
              </Col>
              <Col span={12}>
                <p className="totalAmountValue">Rs.{cartTotal.total || 0}</p>
              </Col>
            </Row>
          </Card>
        </div>
        <p className="choosePayment">Choose Payment Method</p>
        <div>
          <Scrollbars autoHide className="paymentCardsScroll">
            <div style={{ padding: "0px 5px" }}>
              <Card bodyStyle={{ padding: 10 }} className="paymentCard">
                <Row>
                  <Col span={6}>
                    <img src={CreditCard} alt="" />
                  </Col>
                  <Col span={14} style={{ alignSelf: "center" }}>
                    <p className="paymentType">Credit Card</p>
                  </Col>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <Radio className="paymentSelect" />
                  </Col>
                </Row>
              </Card>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <Card bodyStyle={{ padding: 10 }} className="paymentCard">
                <Row>
                  <Col span={6}>
                    <img src={Visa} alt="" />
                  </Col>
                  <Col span={14} style={{ alignSelf: "center" }}>
                    <p className="paymentType">**** **** **** 2187</p>
                  </Col>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <Radio className="paymentSelect" />
                  </Col>
                </Row>
              </Card>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <Card bodyStyle={{ padding: 10 }} className="paymentCard">
                <Row>
                  <Col span={6}>
                    <img src={Paypal} alt="" />
                  </Col>
                  <Col span={14} style={{ alignSelf: "center" }}>
                    <p className="paymentType">Paypal</p>
                  </Col>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <Radio className="paymentSelect" />
                  </Col>
                </Row>
              </Card>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <Card bodyStyle={{ padding: 10 }} className="paymentCard">
                <Row>
                  <Col span={6}>
                    <img src={GooglePay} alt="" />
                  </Col>
                  <Col span={14} style={{ alignSelf: "center" }}>
                    <p className="paymentType">Google Pay</p>
                  </Col>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <Radio className="paymentSelect" />
                  </Col>
                </Row>
              </Card>
            </div>
            <div style={{ padding: "0px 5px" }}>
              <Card bodyStyle={{ padding: 10 }} className="paymentCard">
                <Row>
                  <Col span={6}>
                    <img src={Phonepe} alt="" />
                  </Col>
                  <Col span={14} style={{ alignSelf: "center" }}>
                    <p className="paymentType">PhonePe</p>
                  </Col>
                  <Col span={4} style={{ alignSelf: "center" }}>
                    <Radio className="paymentSelect" />
                  </Col>
                </Row>
              </Card>
            </div>
          </Scrollbars>
        </div>
        <p className="addNew" onClick={handleAddNewPaymentCardModal}>
          Add New +
        </p>
        <div className="nextBtnDivInCart">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              size="large"
              htmlType="submit"
              className="nextBtnInPayment"
              onClick={handlePaymentNext}
            >
              Next
            </Button>
          </Form.Item>
        </div>
        {/* </Scrollbars> */}
      </div>
      <MenuComponent />
      <Modal
        title={null}
        visible={addNewPaymentCardModal}
        closable={false}
        footer={null}
        centered={true}
        width="85%"
        bodyStyle={{ padding: 0 }}
        className="addNewPaymentModal"
      >
        <div>
          <div className="modalHeader">
            <Row>
              <Col span={20}>
                <p className="modalTitle">Add Credit/Debit Card</p>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <img
                  src={CloseIcon}
                  alt=""
                  onClick={handleAddNewPaymentCardModalClose}
                />
              </Col>
            </Row>
          </div>
          <div className="modalBody">
            <Form
              name="newPayment"
              // onFinish={onFinish}
            >
              <Form.Item name="cardNumber" className="addNewCardFormItem">
                <Input
                  type="number"
                  placeholder="Card Number"
                  className="newCardInputs"
                />
              </Form.Item>
              <Row gutter={[8, 0]}>
                <Col span={4} style={{ alignSelf: "center" }}>
                  <p className="expiry">Expiry</p>
                </Col>
                <Col span={8}>
                  <Form.Item name="expiryMonth" className="addNewCardFormItem">
                    <Input
                      type="number"
                      placeholder="MM"
                      className="expiryInput"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="expiryYear" className="addNewCardFormItem">
                    <Input
                      type="number"
                      placeholder="YY"
                      className="expiryInput"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="securityCode" className="addNewCardFormItem">
                <Input placeholder="Security Code" className="newCardInputs" />
              </Form.Item>
              <Form.Item name="firstName" className="addNewCardFormItem">
                <Input placeholder="First Name" className="newCardInputs" />
              </Form.Item>
              <Form.Item name="LastName" className="addNewCardFormItem">
                <Input placeholder="Last Name" className="newCardInputs" />
              </Form.Item>
              <Row>
                <Col span={20} style={{ alignSelf: "center" }}>
                  <p className="cardRemoveText">
                    You can remove this card at anytime
                  </p>
                </Col>
                <Col span={4} className="switchCol">
                  <Switch
                    onChange={handleRemoveCard}
                    size="small"
                    className="removeCardSwitch"
                  />
                </Col>
              </Row>
              <div className="addCardBtnDiv" style={{ marginTop: 20 }}>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button size="large" htmlType="submit" className="addCardBtn">
                    Add Card
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Payment;
