import React, {useEffect} from "react";
import Animate from "rc-animate";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import "../styles.css";
import CWSuitLogo from "../../assets/images/cw-suit.png";
import ShoppingCart from "../../assets/images/cart.gif";

const Spaces = () => {
  const history = useHistory();
  useEffect(() => {
      setTimeout(() => {
        history.push('/login');
     }, 3000)
  }, [history])
  return (
    <Animate transitionName="fade" transitionAppear>
              <div key="1">
    <div className="spacesMain">
      <img src={CWSuitLogo} alt="" width="160px"/>
      <div style={{textAlign: "center"}}>
      <img src={ShoppingCart} alt="" width="100%"/>
      </div>
    </div>
    </div>
          </Animate>
  );
};

export default Spaces;
