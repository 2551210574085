import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Input } from "antd";
import { PlusOutlined, MinusOutlined, SearchOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import Animate from "rc-animate";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import ArrowLeft from "../../assets/images/arrow-left.svg";
import DefaultImage from "../../assets/images/default-product.png";
import "antd/dist/antd.css";
import "../styles.css";
import "../globalStyles.css";
import MenuComponent from "../menuComponent";
import _ from "lodash";

const ProductsPage = () => {
  const history = useHistory();
  const [cart, setCart] = useState({
    lineItems: [],
    total: 0,
    tax: 0,
    totalQty: 0,
  });

  const allProductsList = JSON.parse(
    localStorage.getItem("productsForCategory")
  );
  const subCategoriesList = JSON.parse(localStorage.getItem("subCategories"));
  const selectedSubCategory = JSON.parse(
    localStorage.getItem("selectedSubCategory")
  );
  const selectedSubCategoryProducts =
    JSON.parse(localStorage.getItem("selectedSubCategoryProducts")) || [];
  const [selectedCategory, setSelectedCategory] = useState(selectedSubCategory);
  const [filteredCategoryProducts, setFilteredCategoryProducts] = useState(
    selectedSubCategoryProducts
  );
  const categoriesList = JSON.parse(localStorage.getItem("categories"));
  const [productsSearch, setProductsSearch] = useState("");

  useEffect(() => {
    // const categoryName = JSON.parse(localStorage.getItem("categoryName"));
    const cartItems = JSON.parse(localStorage.getItem("cart"))
      ? JSON.parse(localStorage.getItem("cart"))
      : {
          lineItems: [],
          total: 0,
          tax: 0,
          totalQty: 0,
        };
    setCart(cartItems);
  }, []);

  const backToHome = () => {
    history.push("/home");
  };

  const setDefaultImage = (e) => {
    e.target.src = DefaultImage;
  };

  const handleSubCategory = (value) => {
    setSelectedCategory(value);
    let selectedSubCategoryProducts = _.filter(
      allProductsList,
      (item) => item.categoryId === value.id
    );
    setFilteredCategoryProducts(selectedSubCategoryProducts);
    localStorage.setItem(
      "selectedSubCategoryProducts",
      JSON.stringify(selectedSubCategoryProducts)
    );
  };

  const addProdToCart = (prod, qty) => {
    localStorage.setItem("storeInfo", JSON.stringify(prod.store));
    const weight = parseFloat(qty);
    const addedToCart = [...cart.lineItems] || [];
    const index = addedToCart.findIndex((p) => p.id === prod.id);
    let newObj =
      index >= 0
        ? {
            ...addedToCart[index],
            weight: addedToCart[index].weight + weight,
          }
        : {
            id: prod.id,
            name: prod.name,
            prodCode: prod.sku,
            price: Number(prod.price),
            total: 0,
            tax: 0,
            taxAmount: 0,
            taxRate: 0,
            weight: weight,
            imgUrl: prod.images[0]?.src || "",
            categoryName: prod.prodCategoryName,
          };
    const mrp = parseFloat(newObj.price) * newObj.weight;
    const tax = mrp - mrp / (1 + newObj.taxRate / 100);
    newObj.taxAmount = tax;
    newObj.total = mrp;

    if (index >= 0) {
      if (newObj.weight === 0) {
        addedToCart.splice(index, 1);
      } else {
        addedToCart.splice(index, 1, newObj);
      }
    } else {
      addedToCart.push(newObj);
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    for (let i = 0; i < addedToCart.length; i++) {
      totalPrice += Number(addedToCart[i].total);
      totalItemsQty += addedToCart[i].weight;
      totalTax += Number(addedToCart[i].taxAmount);
      addedToCart[i].key = i;
    }

    let newCart = {
      ...cart,
      lineItems: [...addedToCart],
      total: totalPrice,
      tax: totalTax,
      totalQty: totalItemsQty,
    };

    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
    localStorage.setItem("itemsQuantity", JSON.stringify(totalItemsQty));
  };

  const handleProdSearch = (value) => {
    setProductsSearch(value);
    let selectedSubCategoryProducts = _.filter(
      allProductsList,
      (item) => item.categoryId === selectedCategory.id
    );
    if (value !== "") {
      const results = selectedSubCategoryProducts.filter((prodItem) => {
        return prodItem.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setFilteredCategoryProducts(results);
      localStorage.setItem(
        "selectedSubCategoryProducts",
        JSON.stringify(results)
      );
    } else {
      setFilteredCategoryProducts(selectedSubCategoryProducts);
      localStorage.setItem(
        "selectedSubCategoryProducts",
        JSON.stringify(selectedSubCategoryProducts)
      );
    }
  };

  return (
    <div>
      <div className="mobile-hide">
        <MenuComponent />
        <Scrollbars autoHide className="restCardsScroll-desk">
        <Row className="searchRow-desk">
          <Col span={24} style={{textAlign: "center"}}>
            <Input
              placeholder="Search"
              size="large"
              allowClear={false}
              className="searchInput-desk"
              onChange={(e) => handleProdSearch(e.target.value)}
              value={productsSearch}
              suffix={<SearchOutlined style={{color: "#BBBBBB"}} />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={2} className="subCategoryCol">
            <Scrollbars autoHide className="subCategoriesScroll">
              {subCategoriesList.map((categoryItem, prodIndex) => (
                <Row onClick={() => handleSubCategory(categoryItem)}>
                  <Col span={1}>
                    <div
                      className="selectedItem"
                      style={{
                        visibility:
                          categoryItem.id === selectedCategory.id
                            ? "visible"
                            : "hidden",
                      }}
                    ></div>
                  </Col>
                  <Col span={23} style={{ paddingLeft: 18, paddingRight: 18 }}>
                    <Animate transitionName="fade" transitionAppear>
                      <div key="1" className="subCategoryImgDiv">
                        <img
                          src={
                            _.filter(
                              categoriesList,
                              (item) => item.id === categoryItem.id
                            )[0].image?.src || DefaultImage
                          }
                          alt=""
                          className="subCategoryImg"
                          onError={setDefaultImage}
                        />
                      </div>
                    </Animate>
                    <QueueAnim name="QueueAnim" delay={300}>
                      <div key="a">
                        <p className="subCategoryName">
                          {categoryItem.name.replace("&amp;", "&")}
                        </p>
                      </div>
                    </QueueAnim>
                  </Col>
                </Row>
              ))}
            </Scrollbars>
          </Col>
          <Col span={22} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <p className="selectCategoryName">
              {selectedCategory.name.replace("&amp;", "&")}
            </p>
            <Scrollbars autoHide className="prodCardsScroll">
              <Row gutter={[8, 8]}>
                {filteredCategoryProducts.map((item, index) => (
                  <Col span={4}>
                    <Card
                      bodyStyle={{ padding: 10 }}
                      style={{ height: "32vh", borderRadius: "6px" }}
                    >
                      <Row>
                        <Col span={24}>
                          <Animate transitionName="fade" transitionAppear>
                            <div
                              key="1"
                              style={{ textAlign: "center", marginBottom: 5 }}
                            >
                              <img
                                src={item.images[0]?.src || ""}
                                onError={setDefaultImage}
                                alt=""
                                className="prodImg"
                              />
                            </div>
                          </Animate>
                        </Col>
                      </Row>
                      <QueueAnim name="QueueAnim" delay={300}>
                        <div key="a">
                          <Row>
                            <Col span={24}>
                              <p className="prodName">{item.name}</p>
                            </Col>
                          </Row>
                        </div>
                      </QueueAnim>
                      <QueueAnim name="QueueAnim" delay={300}>
                        <div key="b">
                          <Row style={{ marginTop: 10 }}>
                            <Col span={18}>
                              <p className="prodPrice">
                                &#8377; {item.price} &nbsp;{" "}
                                <span
                                  className="regPrice"
                                  style={{
                                    visibility:
                                      item.sale_price !== ""
                                        ? "visible"
                                        : "hidden",
                                  }}
                                >
                                  &#8377; {item.sale_price}
                                </span>
                              </p>
                            </Col>
                            <Col span={6}>
                              <p className="prodWeight">500g</p>
                            </Col>
                          </Row>
                        </div>
                      </QueueAnim>
                      <QueueAnim name="QueueAnim" delay={300}>
                        <div key="c">
                          <Row style={{marginTop: 10}}>
                            <Col span={10} />
                            <Col span={14}>
                              {cart?.lineItems?.length > 0 &&
                              _.filter(
                                [...cart.lineItems],
                                (data) => data.id === item.id && data.weight > 0
                              )?.length > 0 ? (
                                <p className="IncDecBtns">
                                  <span className="minusBtn">
                                    <MinusOutlined
                                      disabled={
                                        _.filter(
                                          [...cart.lineItems],
                                          (data) =>
                                            data.id === item.id &&
                                            data.weight > 0
                                        )?.[0]?.weight === 0
                                      }
                                      onClick={() =>
                                        addProdToCart(
                                          {
                                            ...item,
                                            prodCategoryName: item.categoryName,
                                          },
                                          -1
                                        )
                                      }
                                    />
                                  </span>
                                  &ensp;
                                  {
                                    _.filter(
                                      [...cart.lineItems],
                                      (data) =>
                                        data.id === item.id && data.weight > 0
                                    )?.[0]?.weight
                                  }
                                  &ensp;
                                  <span className="plusBtn">
                                    <PlusOutlined
                                      onClick={() =>
                                        addProdToCart(
                                          {
                                            ...item,
                                            prodCategoryName: item.categoryName,
                                          },
                                          1
                                        )
                                      }
                                    />
                                  </span>
                                </p>
                              ) : (
                                <Button
                                  className="addButton"
                                  onClick={() =>
                                    addProdToCart(
                                      {
                                        ...item,
                                        prodCategoryName: item.categoryName,
                                      },
                                      1
                                    )
                                  }
                                  size="small"
                                >
                                  Add
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </QueueAnim>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Scrollbars>
          </Col>
        </Row>
        </Scrollbars>
      </div>
      <div className="desktop-hide">
        <Row className="searchRow">
          <Col span={3}>
            <img
              src={ArrowLeft}
              alt="Status"
              style={{
                paddingTop: "10px",
              }}
              onClick={backToHome}
            />
          </Col>
          <Col span={21}>
            <Input
              placeholder="Search"
              size="large"
              allowClear={false}
              className="searchInput"
              onChange={(e) => handleProdSearch(e.target.value)}
              value={productsSearch}
              suffix={<SearchOutlined />}
            />
          </Col>
        </Row>
        <Row>
          <Col span={5} className="subCategoryCol">
            <Scrollbars autoHide className="subCategoriesScroll">
              {subCategoriesList.map((categoryItem, prodIndex) => (
                <Row onClick={() => handleSubCategory(categoryItem)}>
                  <Col span={2}>
                    <div
                      className="selectedItem"
                      style={{
                        visibility:
                          categoryItem.id === selectedCategory.id
                            ? "visible"
                            : "hidden",
                      }}
                    ></div>
                  </Col>
                  <Col span={22} style={{ paddingLeft: 10 }}>
                    <Animate transitionName="fade" transitionAppear>
                      <div key="1" className="subCategoryImgDiv">
                        <img
                          src={
                            _.filter(
                              categoriesList,
                              (item) => item.id === categoryItem.id
                            )[0].image?.src || DefaultImage
                          }
                          alt=""
                          className="subCategoryImg"
                          onError={setDefaultImage}
                        />
                      </div>
                    </Animate>
                    <QueueAnim name="QueueAnim" delay={300}>
                      <div key="a">
                        <p className="subCategoryName">
                          {categoryItem.name.replace("&amp;", "&")}
                        </p>
                      </div>
                    </QueueAnim>
                  </Col>
                </Row>
              ))}
            </Scrollbars>
          </Col>
          <Col span={19} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <p className="selectCategoryName">
              {selectedCategory.name.replace("&amp;", "&")}
            </p>
            <Scrollbars autoHide className="prodCardsScroll">
              <Row gutter={[8, 8]}>
                {filteredCategoryProducts.map((item, index) => (
                  <Col span={12}>
                    <Card
                      bodyStyle={{ padding: 10 }}
                      style={{ borderRadius: "6px" }}
                    >
                      <Row>
                        <Col span={24}>
                          <Animate transitionName="fade" transitionAppear>
                            <div
                              key="1"
                              style={{ textAlign: "center", marginBottom: 5 }}
                            >
                              <img
                                src={item.images[0]?.src || ""}
                                onError={setDefaultImage}
                                alt=""
                                className="prodImg"
                              />
                            </div>
                          </Animate>
                        </Col>
                      </Row>
                      <QueueAnim name="QueueAnim" delay={300}>
                        <div key="a">
                          <Row>
                            <Col span={24}>
                              <p className="prodName">{item.name}</p>
                            </Col>
                          </Row>
                        </div>
                      </QueueAnim>
                      <QueueAnim name="QueueAnim" delay={300}>
                        <div key="b">
                          <Row style={{ marginTop: 10 }}>
                            <Col span={18}>
                              <p className="prodPrice">
                                &#8377; {item.price} &nbsp;{" "}
                                <span
                                  className="regPrice"
                                  style={{
                                    visibility:
                                      item.sale_price !== ""
                                        ? "visible"
                                        : "hidden",
                                  }}
                                >
                                  &#8377; {item.sale_price}
                                </span>
                              </p>
                            </Col>
                            <Col span={6}>
                              <p className="prodWeight">500g</p>
                            </Col>
                          </Row>
                        </div>
                      </QueueAnim>
                      <QueueAnim name="QueueAnim" delay={300}>
                        <div key="c">
                          <Row>
                            <Col span={10} />
                            <Col span={14}>
                              {cart?.lineItems?.length > 0 &&
                              _.filter(
                                [...cart.lineItems],
                                (data) => data.id === item.id && data.weight > 0
                              )?.length > 0 ? (
                                <p className="IncDecBtns">
                                  <span className="minusBtn">
                                    <MinusOutlined
                                      disabled={
                                        _.filter(
                                          [...cart.lineItems],
                                          (data) =>
                                            data.id === item.id &&
                                            data.weight > 0
                                        )?.[0]?.weight === 0
                                      }
                                      onClick={() =>
                                        addProdToCart(
                                          {
                                            ...item,
                                            prodCategoryName: item.categoryName,
                                          },
                                          -1
                                        )
                                      }
                                    />
                                  </span>
                                  &ensp;
                                  {
                                    _.filter(
                                      [...cart.lineItems],
                                      (data) =>
                                        data.id === item.id && data.weight > 0
                                    )?.[0]?.weight
                                  }
                                  &ensp;
                                  <span className="plusBtn">
                                    <PlusOutlined
                                      onClick={() =>
                                        addProdToCart(
                                          {
                                            ...item,
                                            prodCategoryName: item.categoryName,
                                          },
                                          1
                                        )
                                      }
                                    />
                                  </span>
                                </p>
                              ) : (
                                <Button
                                  className="addButton"
                                  onClick={() =>
                                    addProdToCart(
                                      {
                                        ...item,
                                        prodCategoryName: item.categoryName,
                                      },
                                      1
                                    )
                                  }
                                  size="small"
                                >
                                  Add
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </QueueAnim>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Scrollbars>
          </Col>
        </Row>
        <MenuComponent />
      </div>
    </div>
  );
};

export default ProductsPage;
