import React, {useState} from "react";
import { Form, Input, Button, Row, Col, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { fnbURL } from "../../../src/constants/config";
import LeftArrow from "../../assets/images/leftArrow.svg";
import "antd/dist/antd.css";
import "../styles.css";

const SignUp = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const number = JSON.parse(localStorage.getItem("phoneNumber"));
    const token = JSON.parse(localStorage.getItem("clientToken"));
    setLoading(true);
    const getCustomer = {
      query: `mutation {
        upsertCustomer(customer: {
            b2cCustomerId: null,
            firstname: "${values.firstName}",
            lastname: "${values.lastName}",
            mobile_no: "${number}",
            email: "${values.email}"
        }) {
            status
            message
            b2cCustomerId
        }
    }`,
    };
    Axios({
      url: fnbURL,
      method: "POST",
      async: true,
      crossDomain: true,
      data: getCustomer,
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
        clientId: "39BC576048054849BFBFEDBF29142A3E",
      },
    }).then((response) => {
      const customer = response.data.data.upsertCustomer;
      if (customer.status === "200" && customer !== null) {
        let customerId = customer.b2cCustomerId;
        localStorage.setItem("userInfo", JSON.stringify(values));
        localStorage.setItem("customerId", JSON.stringify(customerId));
        history.push("/home");
      } else {
        message.error(customer.message);
      }
    });
  };

  const handleBack = () => {
    history.push("/home");
  };

  const antIcon = <LoadingOutlined spin />;

  return (
    <Spin
        spinning={loading}
        tip="Loading..."
        indicator={antIcon}
        style={{
          position: "relative",
          top: "50vh",
          color: "#18AA57",
        }}
        size="large"
      >
    <div className="signUpMain">
      <div>
        <img src={LeftArrow} alt="" onClick={handleBack} />
      </div>
      <Row style={{ paddingTop: 10 }}>
        <Col span={24}>
          <h1 className="signUpText">Sign Up</h1>
        </Col>
        {/* <Col span={12} style={{ alignSelf: "center" }}>
          <p className="editText">Edit</p>
        </Col> */}
      </Row>
      <Form name="basic" onFinish={onFinish}>
        <label className="inputLabelSignUp">First Name</label>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: "Please input your firstname!" }]}
          className="formItem"
        >
          <Input type="text" size="large" className="signUpInputs" autoComplete="none" />
        </Form.Item>
        <label className="inputLabelSignUp">Last Name</label>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: "Please input your lastname!" }]}
          className="formItem"
        >
          <Input type="text" size="large" className="signUpInputs" autoComplete="none" />
        </Form.Item>
        <label className="inputLabelSignUp">Email</label>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid Email",
            },
            {
              required: true,
              message: "Please input your Email",
            },
          ]}
          className="formItem"
        >
          <Input type="email" size="large" className="signUpInputs" autoComplete="none"/>
        </Form.Item>
        <div className="nextBtnDiv">
          <Form.Item>
            <Button size="large" htmlType="submit" className="submitBtn">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
    </Spin>
  );
};

export default SignUp;
