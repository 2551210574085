import React, { useState } from "react";
import { Row, Col, Button, Form, Input } from "antd";
import { useHistory } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import LeftArrow from "../../assets/images/leftArrow.svg";
import MenuComponent from "../menuComponent";

const { TextArea } = Input;

const RateOrder = () => {
  const history = useHistory();
  const [prodRating, setProdrating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);

  const handleProductRating = (rate) => {
    setProdrating(rate);
  };

  const handleServiceRating = (rate) => {
    setServiceRating(rate);
  };

  const handleFeedback = (values) => {
    console.log("values", values);
    history.push("/home");
  };

  const backToHome = () => {
    history.push("/home");
  };

  return (
    <div>
      <div className="cartMain">
        <Row>
          <Col span={24}>
            <img
              src={LeftArrow}
              alt=""
              className="arrowLeft"
              onClick={backToHome}
            />
          </Col>
        </Row>
        <Form onFinish={handleFeedback} name="feedbackForm">
          <p className="giveFeedback">Give Feedback</p>
          <p className="howWasFood">How was the products?</p>
          <Row style={{ marginBottom: 5 }}>
            <Col span={10}>
              <Rating
                onClick={handleProductRating}
                ratingValue={prodRating}
                size={22}
                allowHover={false}
              />
            </Col>
            <Col span={14} />
          </Row>
          <p className="howWasFood">How was the service provided?</p>
          <Row style={{ marginBottom: 5 }}>
            <Col span={10}>
              <Rating
                onClick={handleServiceRating}
                ratingValue={serviceRating}
                size={22}
                allowHover={false}
              />
            </Col>
            <Col span={14} />
          </Row>
          <Form.Item name="aboutFood" label="Care to share more about the products" className="feedbackFormItem">
            <TextArea rows={2} className="feedbackTextarea" />
          </Form.Item>
          <Form.Item name="aboutServices" label="Care to share more about the service" className="feedbackFormItem">
            <TextArea
              rows={2}
              className="feedbackTextarea"
            />
          </Form.Item>
          <div className="nextBtnDivInCart">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                size="large"
                htmlType="submit"
                className="submitFeedbackBtn"
              >
                Submit Feedback
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <MenuComponent />
    </div>
  );
};

export default RateOrder;
